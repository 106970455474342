import React from 'react'

function Dashboard() {
  return (
    <div>
      <h1>Dashborad</h1>
    </div>
  )
}

export default Dashboard
