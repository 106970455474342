import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

function ValidateVehicle() {

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!location.state) {
      navigate('/choose-vehicle');
    }
    console.log(location.state)
  }, [location.state, navigate]);

  const [inputErrorList, setInputErrorList] = useState({});
  const [exists, setExists] = useState(true);
  const [message, setMessage] = useState('');

  const [formData, setFormData] = useState({
    reg_type: '',
    area_name: 'Bagmati',
    office_code: '',
    lot_no: '',
    symbol: 'Pa',
    vehicle_no: '',
  });
  

  const handleSubmit = async (event) => {
    event.preventDefault();
    getCombinedState();
    console.log(getCombinedState())
    try {
      const response = await axios.post('https://customer.syakarexchange.com/backend/api/validate-customer', formData);
      setExists(response.data.exists);
      setMessage(response.data.message);
      console.log(response.data.exists)
    } catch (error) {
      if (error.response) {
        const { status, data } = error.response;
        if (status === 422) {
          setInputErrorList(data.errors);
        } else if (status === 404) {
          setExists(data.exists);
          setMessage(data.message);
        }
      } else {
        console.error('An error occurred:', error);
      }

    }
  };


  const getCombinedState = () => {
    return { ...location.state, ...formData };
  };


  const handleCreateNewEntry = () => {
    const combinedState = getCombinedState();
    navigate('/customer/honda',{ state: combinedState });
    console.log(combinedState)
  };


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };



  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="card">
          <div className="card-header align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1">Custom Styles</h4>
          </div>
          {/* <!-- end card header --> */}

          <div className="card-body">

            <div className="live-preview">
              <form className="row g-3" onSubmit={handleSubmit}>

                <div className="col-md-4 required-field-mark">
                  <p className="text-muted">कृपया पहिले नै दर्ता गरिएको गाडीको लागि जाँच गर्नुहोस् |</p>

                  <label className="form-label">Registration Type (दर्ता प्रकार)</label>
                  <select className="form-select" name="reg_type" value={formData.reg_type} onChange={handleInputChange} required>
                    <option value="">Choose Registration Type</option>
                    <option value="province">Province (प्रदेश)</option>
                    <option value="zonal">Zonal (अंचल)</option>
                  </select>
                  <span className="text-danger"> {inputErrorList.reg_type}</span>

                  <select className="form-select mt-1" name="area_name" value={formData.area_name} onChange={handleInputChange} required>
                    <option value="">Select Area (क्षेत्र)</option>
                    <option value="Bagmati">Bagmati (बागमती)</option>
                  </select>
                  <span className="text-danger"> {inputErrorList.area_name}</span>

                  {formData.reg_type === 'province' && (
                    <div>
                      <select className="form-select mt-1" name="office_code" value={formData.office_code} onChange={handleInputChange}>
                        <option value="">Select Office Code (कार्यालय कोड)</option>
                        <option value="2">2</option>
                      </select>
                      <span className="text-danger"> {inputErrorList.office_code}</span>
                    </div>
                  )}

                  <input type="text" className="form-control mt-1" name="lot_no" value={formData.lot_no} onChange={handleInputChange} placeholder="Lot No.(लट नं.)" required />
                  <span className="text-danger"> {inputErrorList.lot_no}</span>

                  <select className="form-select mt-1" name="symbol" value={formData.symbol} onChange={handleInputChange} required>
                    <option value="">Select Symbol</option>
                    <option value="Pa">Pa</option>
                  </select>
                  <span className="text-danger"> {inputErrorList.symbol}</span>

                  <input type="text" className="form-control mt-1" name="vehicle_no" value={formData.vehicle_no} onChange={handleInputChange} placeholder="Vehicle No. (सवारी साधन नं.)" required />
                  <span className="text-danger"> {inputErrorList.vehicle_no}</span>

                </div>

                <div className="col-12">
                  <button type="submit" className="btn btn-primary btn-label waves-effect waves-light"><i className="ri-search-line label-icon align-middle fs-16"></i> Search</button>
                </div>

                {exists ? (
                  <p style={{ color: 'red' }}>{message}</p>
                ) : (
                  <div className="col-12">
                    <p style={{ color: 'green' }}>{message}</p>
                    <button type="button" className="btn btn-success btn-label waves-effect waves-light" onClick={handleCreateNewEntry}><i className="ri-add-line label-icon align-middle fs-16"></i> Create New Entry</button>
                  </div>
                )}


              </form>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- end col --> */}
    </div>
  )
}

export default ValidateVehicle
