import React from 'react'
import { Routes, Route } from 'react-router-dom'
import ChooseVehicle from '../pages/customerForm/ChooseVehicle'
import ValidateVehicle from '../pages/customerForm/ValidateVehicle'
import HondaForm from '../pages/customerForm/HondaForm'
import Dashboard from '../pages/Dashboard'


function MyRouter() {
    return (
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/choose-vehicle" element={<ChooseVehicle />} />
          <Route path="/validate-vehicle" element={<ValidateVehicle />} />
          <Route path="/customer/honda" element={<HondaForm />} />
        </Routes>
    )
}

export default MyRouter
