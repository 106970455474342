import { useEffect } from 'react';

const ExternalScriptsLoader = () => {
  useEffect(() => {
    const toastList = document.querySelectorAll("[toast-list]");
    const dataChoices = document.querySelectorAll("[data-choices]");
    const dataProvider = document.querySelectorAll("[data-provider]");

    if (toastList.length > 0 || dataChoices.length > 0 || dataProvider.length > 0) {
      const loadScript = (src) => {
        const script = document.createElement('script');
        script.src = src;
        script.type = 'text/javascript';
        document.body.appendChild(script);
        return script;
      };

      const toastifyScript = loadScript('https://cdn.jsdelivr.net/npm/toastify-js');
      const choicesScript = loadScript(`${process.env.PUBLIC_URL}/assets/libs/choices.js/public/assets/scripts/choices.min.js`);
      const flatpickrScript = loadScript(`${process.env.PUBLIC_URL}/assets/libs/flatpickr/flatpickr.min.js`);

      // Cleanup function to remove the scripts when the component is unmounted
      return () => {
        document.body.removeChild(toastifyScript);
        document.body.removeChild(choicesScript);
        document.body.removeChild(flatpickrScript);
      };
    }
  }, []);

  return null; // This component does not render anything
};

export default ExternalScriptsLoader;
