import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

function ChooseVehicle() {
  const [hondaModels, setHondaModels] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchHondaModels = async () => {
      try {
        const response = await axios.get('https://customer.syakarexchange.com/backend/api/honda-models');
        setHondaModels(response.data.apidata);
        setError('');
      } catch (error) {
        setHondaModels([]);
        setError('Failed to fetch data');
      }
    };

    fetchHondaModels();
  }, []);

  return (
    <div className="row">
      <div className="col-12">

        {error && <div className="alert alert-danger">{error}</div>}

        <div className="row">
          {hondaModels.map((item) => (
            <div className="col-xxl-4" key={item.id}>
              <Link to="/validate-vehicle" state={{ modelstatus: item.status,modelid:item.id }} key={item.id}>
                <div className="card card-primary">
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1 ms-3">
                        <p className="card-text">
                          <span className="fw-medium">{item.name}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}

        </div>
      </div>
    </div>
  );
}

export default ChooseVehicle;
