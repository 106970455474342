import  { useEffect } from 'react';

const HtmlAttributesSetter = () => {
  useEffect(() => {
    document.documentElement.lang = "en";
    document.documentElement.setAttribute("data-layout", "vertical");
    document.documentElement.setAttribute("data-topbar", "light");
    document.documentElement.setAttribute("data-sidebar", "dark");
    document.documentElement.setAttribute("data-sidebar-size", "lg");
    document.documentElement.setAttribute("data-sidebar-image", "none");
    document.documentElement.setAttribute("data-preloader", "disable");

    // Cleanup function to reset attributes if needed when the component is unmounted
    return () => {
      document.documentElement.removeAttribute("data-layout");
      document.documentElement.removeAttribute("data-topbar");
      document.documentElement.removeAttribute("data-sidebar");
      document.documentElement.removeAttribute("data-sidebar-size");
      document.documentElement.removeAttribute("data-sidebar-image");
      document.documentElement.removeAttribute("data-preloader");
    };
  }, []);

  return null; // This component does not render anything
};

export default HtmlAttributesSetter;
