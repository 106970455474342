import React from 'react'
import { Link } from 'react-router-dom'

function Sidebar() {
    return (
        <>
            <div className="app-menu navbar-menu">
                {/* <!-- LOGO --> */}
                <div className="navbar-brand-box">
                    {/* <!-- Dark Logo--> */}
                    <a href="index.html" className="logo logo-dark">
                        <span className="logo-sm">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/logo-sm.png`} alt="" height="22" />
                        </span>
                        <span className="logo-lg">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/logo-dark.png`} alt="" height="17" />
                        </span>
                    </a>
                    {/* <!-- Light Logo--> */}
                    <a href="index.html" className="logo logo-light">
                        <span className="logo-sm">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/logo-sm.png`} alt="" height="22" />
                        </span>
                        <span className="logo-lg">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/logo-light.png`} alt="" height="17" />
                        </span>
                    </a>
                    <button type="button" className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover" id="vertical-hover">
                        <i className="ri-record-circle-line"></i>
                    </button>
                </div>

                <div id="scrollbar">
                    <div className="container-fluid">

                        <div id="two-column-menu">
                        </div>
                        <ul className="navbar-nav" id="navbar-nav">

                            <li className="menu-title"><span data-key="t-menu">Menu</span></li>

                            <li className="nav-item">
                                <Link className="nav-link menu-link" to="/choose-vehicle">
                                    <i className="ri-honour-line"></i> <span data-key="t-widgets">Customer Form</span>
                                </Link>
                            </li>

                        </ul>
                    </div>
                    {/* <!-- Sidebar --> */}
                </div>

                <div className="sidebar-background"></div>
            </div>
            {/* <!-- Left Sidebar End --> */}
            {/* <!-- Vertical Overlay--> */}
            <div className="vertical-overlay"></div>
        </>
    )
}

export default Sidebar
