import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

function HondaForm() {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!location.state) {
            navigate('/choose-vehicle');
        } else {
            const { reg_type, area_name, office_code, lot_no, symbol, vehicle_no } = location.state;
            setHondaInput((prevFormData) => ({ ...prevFormData, reg_type, area_name, office_code, lot_no, symbol, vehicle_no }));
        }
    }, [location.state, navigate]);

    const [inputErrorList, setInputErrorList] = useState({});
    const [message, setMessage] = useState('');
    const [selectedFiles, setSelectedFiles] = useState([]);

    console.log(selectedFiles);

    const handleFileUpload = (file, inputName) => {
        if (file) {
            const reader = new FileReader();
            reader.onload = function(e) {
                const img = new Image();
                img.src = e.target.result;
    
                img.onload = function() {
                    const maxWidth = 1080; // Set your desired max width
                    const maxHeight = 1080; // Set your desired max height
    
                    // Calculate new dimensions to maintain aspect ratio
                    let width = img.width;
                    let height = img.height;
                    if (width > height) {
                        if (width > maxWidth) {
                            height *= maxWidth / width;
                            width = maxWidth;
                        }
                    } else {
                        if (height > maxHeight) {
                            width *= maxHeight / height;
                            height = maxHeight;
                        }
                    }
    
                    // Create a canvas to draw the resized image
                    const canvas = document.createElement("canvas");
                    canvas.width = width;
                    canvas.height = height;
                    const ctx = canvas.getContext("2d");
                    ctx.drawImage(img, 0, 0, width, height);
    
                    // Convert the canvas to a Blob
                    canvas.toBlob(
                        function(blob) {
                            setSelectedFiles(prevFiles => {
                                // Replace the existing file for the same inputName or add a new one
                                const updatedFiles = prevFiles.filter(f => f.name !== inputName);
                                updatedFiles.push({
                                    name: inputName,
                                    blob: blob,
                                    fileName: file.name,
                                });
                                return updatedFiles;
                            });
                        },
                        "image/jpeg",
                        0.8
                    ); // Adjust format and quality as needed
                };
            };
    
            reader.readAsDataURL(file);
        }
    };
    
    

    const [hondaInput, setHondaInput] = useState({
        first_name: '',
        middle_name: '',
        last_name: '',
        mobile_no: '',
        reg_type: '',
        area_name: '',
        office_code: '',
        lot_no: '',
        symbol: '',
        vehicle_no: ''
    });

    // const [imgvehicle, setImgVehicle] = useState(null);

    const handleInputChange = (event) => {
        event.persist();
        setHondaInput({ ...hondaInput, [event.target.name]: event.target.value });
    };

    // const handleImageChange = (event) => {
    //     setImgVehicle(event.target.files[0]);
    // };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        const inputName = event.target.name; // Assuming each input has a unique name
        handleFileUpload(file, inputName);
    };
      

    const handleSubmit = async (e) => {
        e.preventDefault();

        const newFormData = new FormData();
        // newFormData.append('image', imgvehicle);

        Object.keys(hondaInput).forEach((key) => {
            newFormData.append(key, hondaInput[key]);
        });

        selectedFiles.forEach(file => {
            console.log(file);
            newFormData.append(file.name, file.blob, file.fileName);
        });

        try {
            const response = await axios.post('https://customer.syakarexchange.com/backend/api/honda-customer', newFormData);
            setMessage('Form submitted successfully!');
            setInputErrorList({});
            console.log(response.data);
        } catch (error) {
            if (error.response) {
                const { status, data } = error.response;
                if (status === 422) {
                    setInputErrorList(data.errors);
                } else if (status === 404) {
                    setMessage(data.message);
                }
            } else {
                console.error('An error occurred:', error);
            }
        }
    };

    return (
        <div className="row">
            <div className="col-lg-12">
                {/* Success Alert */}
                {message && (
                    <div className="alert alert-success" role="alert">
                        <strong>{message}</strong>
                    </div>
                )}
                <div className="card">
                    <div className="card-header align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">Custom Styles</h4>
                    </div>
                    <div className="card-body">
                        <div className="live-preview">
                            <form className="row g-3" onSubmit={handleSubmit} encType="multipart/form-data">
                                <div className="col-md-3 required-field-mark">
                                    <label htmlFor="first_name" className="form-label">First Name</label>
                                    <input type="text" className="form-control" name="first_name" value={hondaInput.first_name} onChange={handleInputChange} />
                                    <span className="text-danger">{inputErrorList.first_name}</span>
                                </div>

                                <div className="col-md-3">
                                    <label htmlFor="middle_name" className="form-label">Middle Name</label>
                                    <input type="text" className="form-control" name="middle_name" value={hondaInput.middle_name} onChange={handleInputChange} />
                                    <span className="text-danger">{inputErrorList.middle_name}</span>
                                </div>

                                <div className="col-md-3 required-field-mark">
                                    <label htmlFor="last_name" className="form-label">Last Name</label>
                                    <input type="text" className="form-control" name="last_name" value={hondaInput.last_name} onChange={handleInputChange} />
                                    <span className="text-danger">{inputErrorList.last_name}</span>
                                </div>

                                <div className="col-md-3 required-field-mark">
                                    <label htmlFor="mobile_no" className="form-label">Mobile Number</label>
                                    <input type="text" className="form-control" name="mobile_no" value={hondaInput.mobile_no} onChange={handleInputChange} />
                                    <span className="text-danger">{inputErrorList.mobile_no}</span>
                                </div>

                                <div className="col-md-3 required-field-mark">
                                    <label htmlFor="image1" className="form-label">Image1</label>
                                    <input type="file" className="form-control" name="image1" onChange={handleImageChange} />
                                    <span className="text-danger">{inputErrorList.image}</span>
                                </div>

                                <div className="col-md-3 required-field-mark">
                                    <label htmlFor="image2" className="form-label">image2</label>
                                    <input type="file" className="form-control" name="image2" onChange={handleImageChange} />
                                    <span className="text-danger">{inputErrorList.image2}</span>
                                </div>

                                <div className="col-md-4 required-field-mark">
                                    <label className="form-label">Registration Type (दर्ता प्रकार)</label>
                                    <select className="form-select" name="reg_type" value={hondaInput.reg_type} onChange={handleInputChange} readOnly>
                                        <option value="">Choose Registration Type</option>
                                        <option value="province">Province (प्रदेश)</option>
                                        <option value="zonal">Zonal (अंचल)</option>
                                    </select>
                                    <span className="text-danger">{inputErrorList.reg_type}</span>

                                    <select className="form-select mt-1" name="area_name" value={hondaInput.area_name} onChange={handleInputChange} readOnly>
                                        <option value="">Select Area (क्षेत्र)</option>
                                        <option value="Bagmati">Bagmati (बागमती)</option>
                                    </select>
                                    <span className="text-danger">{inputErrorList.area_name}</span>

                                    <select className="form-select mt-1" name="office_code" value={hondaInput.office_code} onChange={handleInputChange} readOnly>
                                        <option value="">Select Office Code (कार्यालय कोड)</option>
                                        <option value="2">2</option>
                                    </select>
                                    <span className="text-danger">{inputErrorList.office_code}</span>

                                    <input type="text" className="form-control mt-1" name="lot_no" value={hondaInput.lot_no} onChange={handleInputChange} placeholder="Lot No.(लट नं.)" readOnly />
                                    <span className="text-danger">{inputErrorList.lot_no}</span>

                                    <select className="form-select mt-1" name="symbol" value={hondaInput.symbol} onChange={handleInputChange} readOnly>
                                        <option value="">Select Symbol</option>
                                        <option value="Pa">Pa</option>
                                    </select>
                                    <span className="text-danger">{inputErrorList.symbol}</span>

                                    <input type="text" className="form-control mt-1" name="vehicle_no" value={hondaInput.vehicle_no} onChange={handleInputChange} placeholder="Vehicle No. (सवारी साधन नं.)" readOnly />
                                    <span className="text-danger">{inputErrorList.vehicle_no}</span>
                                </div>

                                <div className="col-12">
                                    <button className="btn btn-primary" type="submit">Submit form</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HondaForm;
