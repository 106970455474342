import React, { Fragment } from "react";
import MyRouter from "./router";
import HtmlAttributesSetter from './components/HtmlAttributesSetter';
import Topbar from "./components/Topbar";
import Sidebar from "./components/Sidebar";
import Footer from "./components/Footer";
import ExternalScriptsLoader from './components/ExternalScriptsLoader';



function App() {
  return (
    <Fragment>
      <HtmlAttributesSetter />
      <div id="layout-wrapper">

        <Topbar />

        <Sidebar />

        <div className="main-content">


          <div className="page-content">
            <div className="container-fluid">

              {/* <!-- start page title --> */}
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-sm-0">Starter</h4>

                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">Pages</li>
                        <li className="breadcrumb-item active">Starter</li>
                      </ol>
                    </div>

                  </div>
                </div>
              </div>
              {/* <!-- end page title --> */}

              <MyRouter />

            </div>
            {/* <!-- container-fluid --> */}
          </div>
          {/* <!-- End Page-content --> */}



          <Footer />
        </div>
        {/* <!-- end main content--> */}

      </div>
      {/* //  <!-- END layout-wrapper --> */}

      <ExternalScriptsLoader />
    </Fragment>
  );
}

export default App;
